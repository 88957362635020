import { Textarea } from '@mui/joy';
import {
  Box,
  Button,
  Container,
  css,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Setting = () => {
  const navigate = useNavigate();

  const [movingPictureList, setMovingPictureList] = useState<{ id: string; movingPicture: string }[]>([]);
  const [action, setAction] = useState<{ name: string; description: string; moving_picture: string }>({
    name: '',
    description: '',
    moving_picture: '',
  });
  const [uploadedPisture, setUploadedPicture] = useState(false);
  const fileUploadElement = useRef<HTMLInputElement>(null);

  const selectGif = () => {
    fileUploadElement.current!.click();
  };

  const uploadGif = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files.length) {
      return;
    }

    const params: any = { moving_picture: e.target.files[0] };
    const body = Object.keys(params).reduce((o, key) => (o.set(key, params[key]), o), new FormData());

    await fetch(`${process.env.REACT_APP_API_URL}/api/admin/actions/moving_pictures`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: body,
    });
    setUploadedPicture(!uploadedPisture);
  };

  const registerAction = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = JSON.stringify(action);

    await fetch(`${process.env.REACT_APP_API_URL}/api/admin/actions/current`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: body,
    });
    navigate('/');
  };

  useEffect(() => {
    const main = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/actions/moving_pictures`);
      setMovingPictureList(
        ((await response.json()) as any[]).map((datum: any) => {
          return {
            id: datum.id,
            movingPicture: datum.moving_picture,
          };
        }),
      );
    };
    main();
  }, [uploadedPisture]);

  return (
    <Container
      sx={{ minWidth: '100vw', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ width: '100%', height: '100%', display: 'flex', gap: 2 }}>
        <Box sx={{ width: '100%' }}>
          <Button onClick={selectGif} variant='contained'>
            GIFをアップロード
          </Button>
          <input ref={fileUploadElement} onChange={uploadGif} accept='image/*' type='file' hidden />
          <TableContainer sx={{ mb: 5, maxHeight: 'calc(100vh - 100px)' }}>
            <Table>
              <TableHead>
                <TableCell
                  css={css`
                    font-weight: bold;
                  `}
                >
                  ID
                </TableCell>
                <TableCell
                  css={css`
                    font-weight: bold;
                  `}
                >
                  GIF
                </TableCell>
              </TableHead>
              <TableBody>
                {movingPictureList.map((movingPicture) => (
                  <TableRow>
                    <TableCell
                      css={css`
                        font-size: 24px;
                      `}
                    >
                      {movingPicture.id}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => navigator.clipboard.writeText(movingPicture.movingPicture)}>
                        URLをコピー
                      </Button>
                      <br />
                      <a
                        css={css`
                          display: block;
                          margin-top: 8px;
                        `}
                        target='_blank'
                        href={movingPicture.movingPicture}
                      >
                        <img
                          css={css`
                            width: 100px;
                          `}
                          src={movingPicture.movingPicture}
                          alt='画像'
                        />
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ width: '100%' }} component='form' onSubmit={registerAction}>
          <Typography variant='h4' component='h1'>
            最新のアクションを作成
          </Typography>
          <TextField
            onChange={(e) => setAction({ ...action, name: e.target!.value })}
            sx={{ width: '100%', my: 1 }}
            id='name'
            label='タイトルを入力'
          />
          <Textarea
            onChange={(e) => setAction({ ...action, description: e.target!.value })}
            sx={{ width: '100%', my: 1 }}
            id='description'
            placeholder='説明を入力'
            minRows={12}
          />
          <TextField
            onChange={(e) => setAction({ ...action, moving_picture: e.target!.value })}
            sx={{ width: '100%', my: 1 }}
            id='moving_picture'
            label='GIFのURLを入力'
          />
          <Button type='submit' sx={{ width: '100%', my: 2 }} variant='outlined'>
            アクションを作成
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Setting;
