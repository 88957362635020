import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

const Index = () => {
  const [action, setAction] = useState<
    | {
        movingPicture: string;
        name: string;
        description: string;
      }
    | undefined
  >();

  useEffect(() => {
    const main = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/actions/current`);
      if (!response.ok) {
        return;
      }

      const json: {
        moving_picture: string;
        name: string;
        description: string;
      } = await response.json();

      setAction({
        movingPicture: json.moving_picture,
        name: json.name,
        description: json.description,
      });
    };

    main();
  }, []);

  return (
    <div
      css={css`
        width: 100vw;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div>
        <h1
          css={css`
            text-align: center;
            font-size: 48px;
            background-color: #0f0f0f;
            color: #fff;
          `}
        >
          現在の僕
        </h1>
        <div
          css={css`
            display: flex;
            justify-content: center;
            column-gap: 32px;

            @media screen and (max-width: 767px) {
              display: block;
            }
          `}
        >
          <div>
            <p
              css={css`
                color: #333;
                letter-spacing: 2px;
                font-size: 48px;
                width: 400px;
                margin: 24px 0 0 0;

                @media screen and (max-width: 767px) {
                  width: 250px;
                }
              `}
            >
              {action?.name}
            </p>
            <p
              css={css`
                color: #000;
                letter-spacing: 1px;
                font-size: 18px;
                white-space: pre-wrap;
              `}
            >
              {action?.description}
            </p>
          </div>
          {action?.movingPicture ? (
            <img
              css={css`
                display: block;
                width: 400px;
                margin: 0 auto;

                @media screen and (max-width: 767px) {
                  width: 250px;
                }
              `}
              src={action?.movingPicture}
              alt='action'
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
