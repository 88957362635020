import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Index from '../components/pages';
import Setting from '../components/pages/admin/setting';
import NotFound from '../components/pages/notFound';

const RouteConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`*`} element={<NotFound />} />

        <Route path={`/`} element={<Index />} />

        <Route path={`ryo-admin`}>
          <Route path={`*`} element={<Setting />} />
          <Route path={``} element={<Setting />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouteConfig;
