import { css } from '@emotion/react';

const NotFound = () => {
  return (
    <div
      css={css`
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div>
        <h1
          css={css`
            text-align: center;
            font-size: 48px;
          `}
        >
          404 NotFound
        </h1>
        <p
          css={css`
            font-weight: bold;
          `}
        >
          このURLに僕の情報は載ってないよ(´；ω；｀)
        </p>
      </div>
    </div>
  );
};

export default NotFound;
